import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import {
  visionSection,
  visionBox,
  visionImgWrap,
  visionImg,
  description,
  visionDescription,
} from './vision.module.scss';

function Vision() {
  return (
    <section className={visionSection}>
      <div className={visionBox}>
        <div className={visionImgWrap}>
          <StaticImage
            className={visionImg}
            src='../../images/vision.jpg'
            alt='vision image'
          />
        </div>
        <div className={visionDescription}>
          <p className='large-font-medium'>Vision</p>
          <h3>Improve Health outcomes</h3>
          <div className={description}>
            <p className='large-font-medium'>
              Our vision is to minimize errors in healthcare and improve health
              outcomes.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Vision;

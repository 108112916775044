import React from 'react';
import Layout from '../components/layout';
import DigitalPartner from '../components/digital-partner/digitalPartner';
import Mission from '../components/mission/mission';
import Vision from '../components/mission/vision';
import WorkWithUs from '../components/work-withus/workWithUs';
import Contactform from '../components/contactform/contactform';

const About = () => {
  return (
    <Layout pageTitle='About Page'>
      <DigitalPartner />
      <Mission />
      <Vision />
      <Contactform />
      <WorkWithUs />
    </Layout>
  );
};

export default About;

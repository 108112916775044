import React from 'react';
import {
  digitalPartnerSection,
  digitalPartnerBox,
} from './digitalPartner.module.scss';

function DigitalPartner() {
  return (
    <section className={digitalPartnerSection}>
      <div className={digitalPartnerBox}>
        <p className='medium-font-heavy'>About Daily Doc</p>
        <h1>Your Digital Transformation Partner</h1>
        <p className='medium-font-medium'>
          Daily Doc Technologies was conceived to bring innovative technology in
          medicine. Our mission is to make patient care more efficient and
          minimize medical errors. Daily Doc Healthcare App brings the
          technology in today's complex medical environment to give healthcare
          providers tools needed to have effortless, reliable and secure
          communication and bring work schedules online.
        </p>
        <p className='medium-font-medium'>
          Designed by doctors and nurses, we strive to make our platform better
          every day. Honesty and Integrity are our core values.
        </p>
        <p className='medium-font-medium'>
          We strive to innovate in healthcare to bring about positive meaningful
          changes in peoples lives.
        </p>
      </div>
    </section>
  );
}

export default DigitalPartner;

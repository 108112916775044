import React from 'react';
import { workWithUs, workWithUsBox } from './workWithUs.module.scss';

function WorkWithUs() {
  return (
    <section className={workWithUs}>
      <div className={workWithUsBox}>
        <h2>Work with us</h2>
        <p className='medium-font-medium'>
          Our team is growing and we are currently hiring for best human
          capital. We are hiring for following positions.{' '}
        </p>
        <p className='medium-font-medium'>
          * Android native developer (Java or Kotlin)
        </p>
        <p className='medium-font-medium'>
          * Full stack developer (React js and Node js)
        </p>
        <p className='medium-font-medium'>
          * iOS native mobile developer (Swift and Xcode)
        </p>
        <p className='medium-font-medium'> * Software sales representative.</p>
        <div>
          <button className='button button-green'>JOIN THE TEAM</button>
        </div>
      </div>
    </section>
  );
}

export default WorkWithUs;

import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import {
  missionSection,
  missionImgWrap,
  missionImg,
  description,
  missionBox,
  missionDescription,
} from './mission.module.scss';

function Mission() {
  return (
    <section className={missionSection}>
      <div className={missionBox}>
        <div className={missionDescription}>
          <p className='large-font-medium'>Mission</p>
          <h3>Bring Modern Technology to Medicine</h3>
          <div className={description}>
            <p className='large-font-medium'>
              A unique platform for your hospital that simplifies communication
              and makes scheduling easier than ever.
            </p>
          </div>
        </div>
        <div className={missionImgWrap}>
          <StaticImage
            className={missionImg}
            src='../../images/mission.jpg'
            alt='mission image'
          />
        </div>
      </div>
    </section>
  );
}

export default Mission;
